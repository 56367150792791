<template>
    <div class="section" key="express">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 1 Expresspaket</span> Wünschen Sie eine Expressbearbeitung in 4h (Ohne Mehrkosten)</h3>
            <p>Die besonders schnelle Scheidung: Gehen Ihre Unterlagen werktags bis 15 Uhr vollständig bei uns ein, reichen wir Ihren Antrag binnen 4h bei Gericht ein</p>
        </div>
        <div class="form-container">
            <div class="form-card">
                <input type="radio" v-model="user.expressscheidung" name="express" value="ja" id="question-1-1">
                <label for="question-1-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-checked-100.svg"></span><span>Ja, möchte ich</span></label>
            </div>
            <div class="form-card">
                <input type="radio" v-model="user.expressscheidung" name="express" value="nein" id="question-1-2">
                <label for="question-1-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-cancel-100.svg"></span><span>Nein, möchte ich nicht</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Express',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>